<template>
  <div class="meta-right-side-header">
    <div class="top-part">
      <slot name="top"></slot>
    </div>
    <div class="middle-part">
      <span class="middle-part-title" :class="titleClass">
        <span class="truncate"><slot name="title"></slot></span>
        <slot name="title-area"></slot>
      </span>

      <div class="middle-part-right">
        <slot name="middle-right"></slot>
      </div>
    </div>
    <div class="bottom-part">
          <p class="bottom-part-tip">
            <slot name="bottom-tip">
            </slot>
          </p>
        <p class="bottom-part-tip-long">
          <slot name="bottom-tip-long">
          </slot>
        </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MetaRightSideHeader",
  props: ['titleClass']
}
</script>

<style scoped>
.meta-right-side-header{
  height: var(--meta-right-side-header-height);
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
}
.top-part{
  width: 100%;
  height: var(--header-top-part-height);
  flex-shrink: 1;
  position: relative;
}
.middle-part{
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: space-between;
  top: 3rem;
  transform: translateY(-50%);
  z-index: 1;
}
.middle-part-right{
  display: flex;
}
.middle-part-title{
  font-size: 1.25rem;
  color:var(--font-gray);
}
.bottom-part{
  width: 100%;
  height: var(--header-bottom-part-height);
  flex-shrink: 1;
  position: relative;
}
.bottom-part-tip,.bottom-part-tip-long{
  margin:0;
  position: absolute;
  bottom: 0;
  color: var(--font-gray);
}
.bottom-part-tip{
  margin-bottom:1rem;
}
.bottom-part-tip-long{
  margin-bottom:.1rem;
}

</style>
